import { Injectable } from '@angular/core';
import { CrudService } from '@app/global/services/crud.service';
import { PartForm } from '@app/global/models/formly-forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
@Injectable({
  providedIn: 'root',
})
export class PartService {
  public ACTIVE_PART_BATCH: any = null;
  public identifierField: FormlyFieldConfig = {
    key: 'identifier',
    type: 'input',
    id: 'part_identifier',
    props: {
      type: 'text',
      label: 'Part Identifier',
    },
    className: 'custom-ion-input',
  };

  constructor(
    private crud: CrudService,
  ) {}

  getPartDetailById(partId: number) {
    return new Promise((resolve, reject) => {
      this.crud.get('parts', partId).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getPartLIDetailById(partLIId: number) {
    return new Promise((resolve, reject) => {
      this.crud.get('partlineitems', partLIId).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getAllApprovedParts(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.crud.get('parts', '', {approved: true}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

/**
 * Get all active parts
 * @returns Promise of all parts
 */
  getAllPartLineItems(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.crud.list('partlineitems').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    });
  }

  /**
   * Get parts based on page number, search text, and sort text
   * @param page - page number
   * @param searchText - search text
   * @param sortText - sort text
   * @param filters - filters json
   * @returns - Promise of parts
   */
  getParts(
    page: number,
    sortText?: string,
    searchText?: string,
    filters?: any[], 
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let params:any = {
        ordering: sortText,
        search: searchText 
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter) => {
          params[filter.key] = filter.value;
        });
      }
      this.crud.list('parts', page, params).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }

  createPart(data: PartForm) {
    return new Promise((resolve, reject) => {
      this.crud.post('parts', data).then((res) => {
        if (res.status === 201) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  updatePart(data: PartForm, partId: number) {
    return new Promise((resolve, reject) => {
      this.crud.put('parts', partId, data).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getApprovedPartsForProject(projectId: number) {
    return new Promise((resolve, reject) => {
      this.crud.get('parts', '', {project: projectId, approved: true}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getPartBatchList(partId: number, available: boolean) {
    return new Promise((resolve, reject) => {
      this.crud.get('partbatch', '', {part__source: partId, available: available, approved: true}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  createPartBatch(data: any) {
    return new Promise((resolve, reject) => {
      this.crud.post('partbatch', data).then((res) => {
        if (res.status === 201) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getPartBatchDetailById(partBatchId: number) {
    return new Promise((resolve, reject) => {
      this.crud.get('partbatch', partBatchId).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  updatePartBatch(partBatchId: number, data: any) {
    return new Promise((resolve, reject) => {
      this.crud.put('partbatch', partBatchId, data).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
}
