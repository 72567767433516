import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertsService } from '@app/global/services/alerts.service';
import { ServiceRequestsService } from '@app/global/services/service-requests.service';
import { FieldArrayType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-service-request-item',
  templateUrl: './service-request-item.component.html',
  styleUrls: ['./service-request-item.component.scss'],
})
export class ServiceRequestItemComponent extends FieldArrayType implements OnInit, OnDestroy {
  isProjectSelected = false;
  selectedType = null;
  projectSubscription: Subscription;
  typeSubscription: Subscription;

  constructor(
    public serviceRequestServ: ServiceRequestsService,
    private alerts: AlertsService,) { 
    super();
  }

  ngOnInit() {
    this.projectSubscription = this.serviceRequestServ.isProjectSelected.subscribe((updatedValue) => {
      this.isProjectSelected = updatedValue;
      // If the value is cleared/changed and there are items in the item array, clear the array
      if(!this.isProjectSelected){
        if(this.field.fieldGroup && this.field.fieldGroup.length > 0){
          const fieldGroupLength = this.field.fieldGroup.length;
          for (let i = 0; i < fieldGroupLength; i++) {
            this.remove(0);
          }
        }
      }
      // this.ref.detectChanges();

    });

    this.typeSubscription = this.serviceRequestServ.selectedType.subscribe((updatedValue) => {
      this.selectedType = updatedValue;

      // If the value is cleared/changed and there are items in the item array, clear the array
      if(!this.selectedType){
        if(this.field.fieldGroup && this.field.fieldGroup.length > 0){
          const fieldGroupLength = this.field.fieldGroup.length;
          for (let i = 0; i < fieldGroupLength; i++) {
            this.remove(0);
          }
        }
      }
    });
  }
  
  ngOnDestroy() {
    if(this.projectSubscription){
      this.projectSubscription.unsubscribe();
    }
    if(this.typeSubscription){
      this.typeSubscription.unsubscribe();
    }
  }

  async addItem() {
    if(!this.serviceRequestServ.itemDataFetched){
      await this.serviceRequestServ.fetchItemData();
    }
    await this.serviceRequestServ.updateItemFormFields(this.field);
    this.add();
  }

  removeItem(itemIndex: number) {
    // if (this.serviceRequestServ.showDeleteAlert) {
    //   this.showDeleteItemPrompt(itemIndex);
    // } else {
      this.removeField(itemIndex);
    // }
  }

  async removeField(i: number) {
    const decision = await this.alerts.showPromisableAlert(
      'Remove Item',
      'Are you sure you want to remove this item from the request?',
      true,
      'Remove',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      // this.serviceRequestServ.selectedPoItem.splice(i, 1);
      this.remove(i);
    }
  }

  // async showDeleteItemPrompt(itemIndex: number) {
  //   const decision = await this.alerts.showPromisableAlert(
  //     'Delete Item',
  //     'Deleting this item will remove the quantity from records, and mark the related Purchase item undelivered. Are you sure you want to remove this item ?',
  //     true,
  //     'Delete',
  //     'Cancel',
  //     'danger',
  //     'medium'
  //   );
  //   if (decision) {
  //     this.serviceRequestServ.deleteserviceRequest(this.serviceRequestServ.selectedserviceRequest).then(() => {
  //       this.alerts.showToast('Deleted the Inventory In Successfully');
  //       this.serviceRequestServ.selectedPoItem = [];
  //     });
  //   }
  // }

  ngOnChanges() {
  }

}
