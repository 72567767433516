import { Injectable, isDevMode } from '@angular/core';
import { Approval, ContentType, ExtraServerSetting, MatCat, UserDetail } from '@app/global/models/basic';
import { ContentTypeToPageMapping } from '@app/global/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class SharedVarsService {
  public META_DATA: any = {};
  public IS_MOBILE: boolean = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  public IS_MOCK: boolean = false;
  public VIEW_ORDER: number = 0;
  public MATERIAL_CATEGORIES: MatCat[] = [];
  public MATERIAL_UNITS: any[] = [];
  public LEAVE_TYPES: any[] = [];
  public DEPARTMENTS: any[] = [];
  public HSN_CODES: any[] = [];
  public GST_RATES: any = {};
  public CONTENT_TYPES: ContentType[] = [];
  public USER_DETAIL: UserDetail = {} as UserDetail;
  public EXTRA_SERVER_SETTINGS: ExtraServerSetting[] = [];
  public MAINTENANCE_MODE: boolean = false;
  public APPROVAL_CHANGE_LIMIT: number = 2;
  public HIDE_MENU = false;
  public GLOBAL_LOADER: any = {
    show: false,
    text: '',
  }
  public DEV_MODE: boolean = isDevMode();
  public MATR_TO_PURR_IN_PROGRESS: boolean = false;
  public MATR_TO_RESR_IN_PROGRESS: boolean = false;
  public VERSION: string = '0.1.4';

  constructor() {}
  
  getRedirectPage(approval: Approval) {
    if(!approval || !approval.type) return;
    const pageToRedirect = ContentTypeToPageMapping[approval.type];
    return pageToRedirect;
  }
}
