import { Injectable } from '@angular/core';
import {
  AlertController,
  PopoverController,
  ToastController,
  ModalController,
  AlertButton,
  ToastOptions,
} from '@ionic/angular';
import { SharedVarsService } from '@app/global/services/shared-vars.service';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  public toasts: any[] = [];
  public toast: any;
  public modal: HTMLIonModalElement;
  public alert: HTMLIonAlertElement;
  private modalCount: number = 0;
  constructor(
    private toastCtrl: ToastController,
    private sharedVars: SharedVarsService,
    public modalController: ModalController,
    private alertController: AlertController
  ) {}

  async showToast(
    message: string,
    position: ToastOptions['position'] = 'bottom',
    duration = 2500,
    state = 'success',
    func: any = null
  ) {
    const cssClass = this.toasts?.length ? `floating-${this.toasts?.length}`:'';
    const toast = await this.toastCtrl.create({
      message,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {
            func ? func() : null;
            return false;
          },
        },
      ],
      position,
      duration,
      color: state,
      cssClass: cssClass,
    });
    toast.onDidDismiss().then(() => {
      this.toasts.pop();
    });
    this.toasts.push(toast);
    toast.present();
  }

  async dismissToast() {
    return new Promise((resolve) => {
      let toastPromises: any = [];
      if (this.toasts && this.toasts.length) {
        this.toasts.forEach(async (toast) => {
          toastPromises.push(await toast.dismiss());
        });
      }
      Promise.all(toastPromises).then(() => {
        this.toasts = [];
        resolve(true);
      });
    });
  }

  /**
   * Present general modal
   * @param component - Component for modal
   * @param cssClass - css class name
   * @param props - data to be sent to component
   * @param backdropDismiss
   * @returns
   */
  async presentGeneralModal(
    component: any,
    cssClass = '',
    props = {},
    backdropDismiss: boolean = false
  ) {
    this.sharedVars.VIEW_ORDER++;
    const modal = await this.modalController.create({
      component: component,
      backdropDismiss: backdropDismiss,
      cssClass: cssClass,
      componentProps: props,
    });
    this.modalCount++;
    return await modal.present();
  }

  /**
   * Dismiss modal one by one
   * for all available ones
   */
  async dismissModal(latest = true, data?: any) {
    for (let i = this.modalCount; i > 0; i--) {
      try {
        await this.modalController.dismiss(
          i === this.modalCount ? data : undefined
        );
        if (this.modalCount > 0) {
          this.modalCount--;
          this.sharedVars.VIEW_ORDER--;
        }
        if (latest) {
          break;
        }
      } catch {
        this.modalCount = 0;
      }
    }
  }

  /**
   * Dismiss modal one by one
   * for all available ones
   */
  async dismissFormModalWithWarning() {
    const warning = 'You have unsaved changes. Are you sure you want to discard them?';
    const decision = await this.showPromisableAlert(
      'Close',
      warning,
      true,
      'Yes',
      'No',
      'danger',
      'medium'
    );
    if (decision) {
      this.dismissModal();
    }
  }

  presentResolvableGeneralModal(
    component: any,
    cssClass = '',
    backdropDismiss: boolean = false,
    data?: any,
    extraParams?: any
  ) {
    this.sharedVars.VIEW_ORDER++;
    cssClass =
      cssClass + (this.sharedVars.VIEW_ORDER == 1 ? ' modal-1' : ' modal-2');

    return new Promise(async (resolve) => {
      this.sharedVars.VIEW_ORDER++;
      const modal = await this.modalController.create({
        component: component,
        backdropDismiss: backdropDismiss,
        cssClass: cssClass,
        componentProps: { data: data, extraParams: extraParams },
      });
      modal.onDidDismiss().then((data) => {
        resolve(data);
        this.sharedVars.VIEW_ORDER--;
      });
      this.modalCount++;
      await modal.present();
    });
  }

  async showAlert(
    _header: any,
    _message: any,
    multiButton:boolean = false,
    okText: string = 'OK',
    cancelText: string = 'Cancel',
    okButtonClass: string = '',
    cancelButtonClass: string = '',
    func: any = null,
    cssClass: string = ''
  ) {
    let buttonArray: (string | AlertButton)[] = [
      {
        text: okText,
        cssClass: okButtonClass,
        handler: () => {},
      },
    ];
    if (multiButton) {
      buttonArray.unshift({
        text: cancelText,
        role: 'cancel',
        cssClass: cancelButtonClass,
        handler: () => {},
      });
    }
    if (func && func.param) {
      buttonArray.unshift({
        text: func.title,
        cssClass: func.css,
        handler: () => {
          func.func(func.param);
          return false;
        },
      });
    }
    this.alert = await this.alertController.create({
      cssClass: 'custom-alert '+(cssClass ? ' '+cssClass : ''),
      header: _header,
      message: _message,
      buttons: buttonArray,
    });

    await this.alert.present();
  }
  /*
   ** Promisable alert
   ** returns boolean
   */
  showPromisableAlert(
    _header: any,
    _message: any,
    multiButton = true,
    okText = 'OK',
    cancelText = 'Cancel',
    okButtonClass = '',
    cancelButtonClass = '',
    cssClass = null,
    extraButtons: any = null
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const _buttons: AlertButton[] = multiButton
        ? [
            {
              text: cancelText,
              role: 'cancel',
              cssClass: cancelButtonClass,
              handler: (blah) => {
                resolve(false);
              },
            },
            {
              text: okText,
              cssClass: okButtonClass,
              handler: () => {
                resolve(true);
              },
            },
          ]
        : [
            {
              text: okText,
              handler: () => {
                resolve(true);
              },
            },
          ];
      if (extraButtons) {
        extraButtons.forEach((button: any) => {
          _buttons.push({
            text: button.text,
            role: button?.role,
            cssClass: button?.class ? button?.class : '',
            handler: (blah) => {
              button.callback();
              resolve(button?.role ? true : false);
            },
          });
        });
      }
      this.alert = await this.alertController.create({
        cssClass: cssClass ? cssClass : 'custom-alert',
        header: _header,
        message: _message,
        backdropDismiss: false,
        buttons: _buttons,
      });

      await this.alert.present();
    });
  }

  closeAlert() {
    if (this.alert) this.alert.dismiss();
  }
  showApiErrorAlert(err: any, item: string, action: string = '') {
    const itemAction = 'to ' + (item && action ? action + ' ' + item : 'for this action.');
    if (err?.response?.status === 403) {
      this.showToast(`You don't have permission ${itemAction}`, "bottom", 3000, "danger")
    }
    else if (err?.response?.status === 404) {
      this.showToast("The resource or data does not exist", "bottom", 3000, "danger")
    }
    else{
      this.showToast(`Error loading ${item}`, "bottom", 3000, "danger")
    }
  }
  /**
   * Expects an error object at err.response.data. This object can be 
   * either key-value pairs or key-array of strings.
   * @param err - HTML error object
   * @returns 
   */
  getErrorText(err: any) {
    let err_string = '';
    if (err?.response?.data && Object.keys(err.response.data).length > 0) {
      const err_object = err.response.data[Object.keys(err.response.data)[0]];
      if (typeof err_object === 'string' || err_object instanceof String){
        err_string = err_object.charAt(0).toUpperCase() + err_object.slice(1);
      }
      else if(Object.keys(err_object).length > 0){
        const first = err_object[Object.keys(err_object)[0]];
        err_string = first.charAt(0).toUpperCase() + first.slice(1);
      }
    }
    else{
      if (err?.response?.status === 403) {
        err_string = "You don't have permission to perform this action";
      }
    }
    return err_string;
  }
  showApiErrorToast(err: any, defaultText='An error occurred', time=4000, state='danger') {
    const err_txt = this.getErrorText(err) || defaultText;
    this.showToast(err_txt, "bottom", time, state);
  }
  showGlobalLoader(message: string, duration: number = 0) {
    return new Promise(async (resolve) => {
      this.sharedVars.GLOBAL_LOADER.text = message;
      this.sharedVars.GLOBAL_LOADER.show = true;
      setTimeout(() => {
        this.sharedVars.GLOBAL_LOADER.show = false;
        resolve(true);
      }, duration);
    });
  }
}
