import { Injectable } from '@angular/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { CrudService } from '@app/global/services/crud.service';
import { Vendor } from '@app/global/models/basic';
import { VendorForm } from '@app/global/models/formly-forms';
import { AlertsService } from './alerts.service';
@Injectable({
  providedIn: 'root',
})
export class VendorService {
  constructor(
    private sharedVars: SharedVarsService,
    private crud: CrudService,
    private alerts: AlertsService
  ) {}

  getVendorsByService(serviceId: number, extraParams?: any): Promise<Vendor[]> {
    return new Promise((resolve, reject) => {
      let params:any = {}
      if (serviceId) {
        params.operation = serviceId;
      }
      if (extraParams) {
        params = {
          ...params,
          ...extraParams
        }
      }
      this.crud.list('vendors', 0, params).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  
  getVendorsByMaterial(materialId: number, mmid?: number, extraParams?: any): Promise<Vendor[]>{
    return new Promise((resolve, reject) => {
      let params:any = {}
      if (materialId) {
        params.material = materialId;
      }
      if (mmid) {
        params.mmid = mmid;
      }
      if (extraParams) {
        params = {
          ...params,
          ...extraParams
        }
      }
      this.crud.list('vendors', 0, params).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getVendorDetailById(vendorId: number) {
    return new Promise((resolve, reject) => {
      this.crud.get('vendors', vendorId).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getVendorLIDetailById(vendorLIId: number) {
    return new Promise((resolve, reject) => {
      this.crud.get('vendorlineitems', vendorLIId).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getAllApprovedVendors(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.crud.get('vendors', '', {approved: true}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }

    /**
   * Get all active vendors
   * @returns Promise of all vendors
   */
    getAllVendorLineItems(): Promise<any> {
      return new Promise((resolve, reject) => {
        this.crud.list('vendorlineitems').then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
      });
    }

  /**
   * Get vendors based on page number, search text, and sort text
   * @param pageNumber - page number
   * @param query - search text
   * @param sort - sort text
   * @returns - Promise of vendors
   */
  getVendors(pageNumber?: number, query?: string, sort?: string, filter?: string, filterValue?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const options:any = {search: query, ordering: sort};
      if (filter && filterValue) {
        options[filter] = filterValue;
      }
      this.crud.list('vendors', pageNumber, options).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }

  createVendor(data: VendorForm) {
    return new Promise((resolve, reject) => {
      this.crud.post('vendors', data).then((res) => {
        if (res.status === 201) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  updateVendor(data: VendorForm, vendorId: number) {
    return new Promise((resolve, reject) => {
      this.crud.put('vendors', vendorId, data).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  createContactPerson(data: any) {
    return new Promise((resolve, reject) => {
      this.crud.post('contactpersons', data).then((res) => {
        if (res.status === 201) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  updateContactPerson(data: any) {
    return new Promise((resolve, reject) => {
      this.crud.put('contactpersons', data.id, data).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  removeContactPerson(id: number) {
    return new Promise((resolve, reject) => {
      this.crud
        .patch('contactpersons', id, { is_deleted: true })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
