import { Injectable } from '@angular/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { CrudService } from '@app/global/services/crud.service';
import { Mmid } from '@app/global/models/basic';

@Injectable({
  providedIn: 'root',
})
export class MmidService {
  constructor(
    private sharedVars: SharedVarsService,
    private crud: CrudService
  ) {}

  getMmidByMaterialAndVendor(materialId: number, vendorId?: number, extraParams?: any) {
    return new Promise((resolve, reject) => {
      let params:any = {
        material: materialId, 
      }
      if (vendorId){
        params.vendor = vendorId;
      }
      if (extraParams) {
        params = {
          ...params,
          ...extraParams
        }
      }
      this.crud.list('mmids', 1, params).then((res) => {
        if (res.status === 200) {
          resolve(res.data.results);
        }
      });
    });
  }

  getMmidByVendor(vendorId: number) {
    return new Promise((resolve, reject) => {
      this.crud.list('mmids', 1, {vendor: vendorId}).then((res) => {
        if (res.status === 200) {
          resolve(res.data.results);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  addOrRemoveVendorFromMmid(id: number, vendorId: number, action: string) {
  return new Promise((resolve, reject) => {
      this.crud.patch('mmids', id, {action: action, vendor: vendorId }).then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getAllMmids(): Promise<any>{
    return new Promise((resolve, reject) => {
      this.crud.list('mmids').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getAllApprovedMmids(): Promise<any>{
    return new Promise((resolve, reject) => {
      this.crud.get('mmids', '', {approved: true}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getStockReport(){
    return new Promise((resolve, reject) => {
      this.crud.get('stock-report').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getMmidsByCategoryId(id: number | null, pageNumber: number, searchText: string, sortText?: string){
    return new Promise((resolve, reject) => {
      this.crud.list('mmids', pageNumber, {category: id, search: searchText, ordering: sortText}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getMmidById(id: number){
    return new Promise((resolve, reject) => {
      this.crud.get('mmids', id).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getMmidLIById(id: number){
    return new Promise((resolve, reject) => {
      this.crud.get('mmidlineitems', id).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getAllReservedMmids(){
    return new Promise((resolve, reject) => {
      if (this.sharedVars.IS_MOCK) {
        // setTimeout(() => {
        //   resolve(__MMIDS);
        // }, 500);
      } else {
        //TODO Call API with vendor filter
      }
    });
  }
  getReservedMmidsByCategoryId(id: number | null, pageNumber: number, searchText: string, sortText?: string){
    return new Promise((resolve, reject) => {
      this.crud.list('reserved', pageNumber, {category: id, search: searchText, ordering: sortText}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  createMmid(data: any) {
    return new Promise((resolve, reject) => {
      this.crud.post('mmids', data).then((res) => {
        if (res.status === 201) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  updateMmid(data: any, id: number) {
    return new Promise((resolve, reject) => {
      this.crud.put('mmids', id, data).then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  removeMmid(id: number) {
    return new Promise((resolve, reject) => {
      this.crud.patch('mmids', id, {is_deleted: true}).then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  updateMmidWithVendor(mmId: number, vendorPayload: any) {
    return new Promise((resolve, reject) => {
      this.crud.patch('mmids', mmId, vendorPayload).then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getAllMmidLineItems(): Promise<any>{
    return new Promise((resolve, reject) => {
      this.crud.list('mmidlineitems').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
}
