<ion-row>
  <ion-col class="ion-text-left">
    <h4>{{ props.label }}</h4>
  </ion-col>
  <ion-col class="ion-text-right">
    <ion-button *ngIf="!(quoteServ.IS_APPROVER || quoteServ.IS_APPROVER_ASSIGNED || quoteServ.IS_PURCHASE_REQUEST_CANCELLED)" (click)="addQuote()" color="success">Add Quote</ion-button>
  </ion-col>
</ion-row>
<ion-row *ngFor="let quote of field.fieldGroup; let i = index" >
  <ion-col>
    <ion-row class="ion-align-items-center">
      <ion-col class="ion-text-left">
        <ion-text color="medium">Quotation {{ i + 1 }}</ion-text>
      </ion-col>
      <ion-col class="ion-text-right">
        <ion-button *ngIf="!(quoteServ.IS_APPROVER || quoteServ.IS_APPROVER_ASSIGNED || quoteServ.IS_PURCHASE_REQUEST_CANCELLED)" (click)="removeField(i)" color="danger" fill="clear">
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="quote-item">
      <ion-col>
        <formly-field [field]="quote"></formly-field>
        <ion-row>
          <ion-col>
            <docs-wrapper [id]="'docs_wrapper_'+i" [title]="'Quotes Documents'"
            [parentId]="0" [model]="'quote'"
            [canAddDocs]="!(quoteServ.IS_APPROVER || quoteServ.IS_APPROVER_ASSIGNED || quoteServ.IS_PURCHASE_REQUEST_CANCELLED)"
            [canEditDocs]="!(quoteServ.IS_APPROVER || quoteServ.IS_APPROVER_ASSIGNED || quoteServ.IS_PURCHASE_REQUEST_CANCELLED)"
            (afterUpload)="onFileSelected($event, i)"
            [docs]="quote.fieldGroup![docsFieldIndex].formControl?.value"
            (afterRemove)="removeDocument($event, i)"
            [layout]="'card'"
            [childIndex]="i"
            ></docs-wrapper>
          </ion-col>
          <ion-col></ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
