<ion-row>
  <ion-col class="ion-text-left">
    <h4>{{ props.label }}</h4>
  </ion-col>
  <ion-col class="ion-text-right">
    <ion-button [disabled]="!selectedProject || !selectedType" (click)="addItem()" color="success">Add Item</ion-button>
  </ion-col>
</ion-row>
<ion-row *ngFor="let _field of field.fieldGroup; let i = index" class="ion-padding-bottom ion-margin-bottom">
  <ion-col>
    <div class="item-wrapper">
      <div class="item-number"><ion-text color="medium">{{ i + 1 }}</ion-text></div>
      <formly-field class="inline-field" [field]="_field"></formly-field>
      <ion-button (click)="removeField(i)" color="danger" fill="clear">
        <ion-icon name="trash"></ion-icon>
      </ion-button>
    </div>
  </ion-col>
</ion-row>
