export const DEFAULT_REDIRECT_TO = 'materials';

export const PaginationConstants = {
  DEFAULT_PAGE_NUMBER: 1,
  DEFAULT_PAGE_COUNT: 0,
  ITEMS_PER_PAGE: 10,
};
export const ContentTypeToPageMapping = {
  ADD_EMPLOYEE: 'users',
  ADD_MANUFACTURER_TO_MATERIAL: 'materials', // ??
  BAN_VENDOR: 'vendors',
  BAN_MMID: 'mmids',
  BAN_VENDOR_MMID: 'vendors',
  INVENTORY_IN: 'inventory-ins',
  MATERIAL_BATCH: 'inventory-ins',
  INVENTORY_OUT: 'inventory-outs',
  INVENTORY_OUT_REQUEST : 'inventory-out-request',
  MATERIAL: 'materials',
  PURCHASE_REQUEST: 'purchase-requests',
  MATERIAL_REQUEST: 'material-requests',
  NAC: 'purchase-requests',
  NC: 'non-conformances',
  LEAVE: 'leaves',
  PAYMENT: 'payments',
  PURCHASE_ORDER: 'purchase-orders',
  QUOTE: 'quotes',
  RESERVE_INVENTORY: 'inventory-reservations',
  RESET_PASSWORD: 'users',
  VENDOR: 'vendors',
  PAYMENT_IMMINENT: 'payments',
  PAYMENT_OVERDUE: 'payments',
  DEFAULT: 'dashboard',
  MMID: 'mmids',
  PROJECT: 'projects',
  MANUFACTURER: 'manufacturers',
  PART: 'parts',
  SERVICE_REQUEST: 'service-requests',
  PART_BATCH: 'inventory-ins',
};

export const NotificationTypeMapping:any = {
  ADD_EMPLOYEE : 'User',
  INVENTORY_IN : 'Inv-In',
  MATERIAL_BATCH :  'Mat Batch',
  INVENTORY_OUT :  'Inv-Out',
  INVENTORY_OUT_REQUEST :  'Inv-Out Request',
  MANUFACTURER : 'Manufacturer',
  MATERIAL :  'Material',
  PURCHASE_REQUEST : 'Purchase Request',
  MATERIAL_REQUEST : 'Material Request',
  MMID: 'MMID',
  LEAVE : 'Leave',
  PAYMENT : 'Payment',
  PURCHASE_ORDER : 'PO',
  QUOTE : 'Quotation',
  RESERVE_INVENTORY : 'Reserve-Inv',
  RESERVE_INVENTORY_REQUEST : 'Reserve-Inv Request',
  NEED_ASPECT: 'Need Aspect',
  VENDOR : 'Vendor',
  PROJECT : 'Project',
  PART : 'Part',
  SERVICE_REQUEST : 'Service Request',
  PART_BATCH : 'Part Batch',
  RETURNABLE: 'Returnable',
}

export const SESSION_CONSTANTS = {
    SESSION_IDLE_TIMEOUT: 480,
    SESSION_WARNING_TIMEOUT: 120
}

export const PAYMENT_OPTIONS = [
  {
    label: 'CASH',
    value: 'CASH'
  },
  {
    label: 'CHEQUE',
    value: 'CHEQUE'
  },
  {
    label: 'NEFT',
    value: 'NEFT'
  },
  {
    label: 'RTGS',
    value: 'RTGS'
  },
  {
    label: 'IMPS',
    value: 'IMPS'
  },
  {
    label: 'UPI',
    value: 'UPI'
  },
  {
    label: 'OTHER',
    value: 'OTHER'
  },

]

export const PAYMENT_TYPE = {
  ADVANCE: 'ADVANCE',
  BALANCE: 'BALANCE',
  CUSTOM : 'CUSTOM'
}

export const PAYMENT_FILTER_OPTION = [
  {'label': 'Due Date', 'value': 'expected_payment_date'},
  {'label': 'Paid On','value': 'payment_date'}
];

export const PO_SELF_ADDRESS = {
  name: 'Fabheads Automation Private Limited',
  address: 'Plot 86, 14th Link Road, Venkateshwara Colony',
  street: 'Nehru Nagar, Kottivakkam',
  city: 'Chennai',
  pincode: '600041',
  state: 'Tamil Nadu',
  country: 'India',
  gst_no: '33AACCF7179C1ZS'
}

export const ProjectTypeList = [
  { label: 'RND', value: 'RND' },
  { label: 'MACHINE', value: 'MACHINE' },
  { label: 'SERVICE', value: 'SERVICE' },
  { label: 'GENERAL', value: 'GENERAL' }
]