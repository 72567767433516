import { Injectable } from '@angular/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { CrudService } from '@app/global/services/crud.service';
import { AlertsService } from './alerts.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ProjectForm } from '@app/global/models/formly-forms';
import { Project, ProjectDetail } from '../models/basic';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private sharedVars: SharedVarsService,
    private crud: CrudService,
    private alerts: AlertsService
    ) {}
  createProject(data: ProjectForm) {
    return new Promise((resolve, reject) => {
      this.crud.post('projects', data).then((res) => {
        if (res.status === 201) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  updateProject(data: ProjectForm, projectId: number) {
    return new Promise((resolve, reject) => {
      this.crud.put('projects', projectId, data).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getAllProjects(
    page: number,
    sortText?: string,
    searchText?: string,
    filters?: any[]): Promise<Project[]> {
    return new Promise((resolve, reject) => {
      let params:any = {
        ordering: sortText,
        search: searchText 
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter) => {
          params[filter.key] = filter.value;
        });
      }
      this.crud.list('projects', page, params).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getApprovedProjects(): Promise<Project[]> {
    return this.getAllProjects(0, '', '', [{key: 'approved', value: true}]);
  }
  getOngoingProjects() {
    return this.getAllProjects(0, '', '', [{key: 'status', value: 'ONGOING'}]);
  }
  getValidProjects() {
    return this.getAllProjects(0, '', '', [{key: 'status_ne', value: 'CANCELED'}, {key: 'approved', value: true}]);
  }
  getProjectDetail(id: number): Promise<ProjectDetail> {
    return new Promise((resolve, reject) => {
      this.crud.get('projects', id).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getProjectBudgetDetails() {
    return new Promise((resolve, reject) => {
      this.crud.get('project-budget').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
}
