import { Injectable } from '@angular/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { AddInventoryInForm } from '@app/global/models/formly-forms';
import { CrudService } from './crud.service';
import { BehaviorSubject } from 'rxjs';
import { InventoryInDetail } from '../models/basic';
import { FormlyFieldsService } from './formly-fields.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryInService {
  public isPoSelected = new BehaviorSubject(false);
  public isEditInstance = false;
  public selectedInventoryIn = 0;
  public selectedPoItem: any = [];
  public selectedPo: any = {};
  constructor(
    private sharedVars: SharedVarsService, 
    private crud: CrudService,
    private formlyServ: FormlyFieldsService
  ) {}
  getInventoryInById(materialId: number): Promise<InventoryInDetail> {
    return new Promise((resolve, reject) => {
      this.crud.get('inventoryin', materialId).then((res) => {
        if (res.status === 200) {
          resolve(res.data)
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  getAllInventoryIns(
    page?: number, 
    sortText?: string, 
    searchText?: string, 
    filters?: any[]
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let params:any = {
        ordering: sortText,
        search: searchText 
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter) => {
          params[filter.key] = filter.value;
        });
      }
      this.crud.list('inventoryin', page, params).then((res) => {
        if (res.status === 200) {
          resolve(res.data)
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  createInventoryIn(inventoryInPayload: any) {
    return new Promise((resolve, reject) => {
      this.crud.post('inventoryin/bulk_create', inventoryInPayload).then((res) => {
        if (res.status === 201) {
          resolve(res)
        }
      }).catch((err) => {
        reject(err);
      });
    }); 
  }

  cancelInventoryIn(inventoryInId: number) {
    return new Promise((resolve, reject) => {
      this.crud.patch('inventoryin', inventoryInId, {is_canceled: true}).then((res) => {
        if (res.status === 200) {
          resolve(res)
        }
      }).catch((err) => {
        reject(err);
      });
    }); 
  }

  updateInventoryIn(inventoryInId: number, inventoryInData: any) {
    return new Promise((resolve, reject) => {
      this.crud.patch('inventoryin', inventoryInId, inventoryInData).then((res) => {
        if (res.status === 200) {
          resolve(res)
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  updateMaterialBatch(inventoryInId: number, materialBatchData: any) {
    return new Promise((resolve, reject) => {
      this.crud.put('materialbatch', inventoryInId, materialBatchData).then((res) => {
        if (res.status === 200) {
          resolve(res)
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  /**
   * When an item is added in inventory-in form, 
   * check if the PO is a service request or a purchase request PO.
   * Based on that, show/hide the batch fields.
   * @param purchaseItemField 
   */
  showHideBatchFields(purchaseItemField: any){
    const _partBatchField = this.formlyServ.findArrayNestedDataField(purchaseItemField, 'part_batch');
    const _matBatchField = this.formlyServ.findArrayNestedDataField(purchaseItemField, 'mat_batch');
    if(this.selectedPo.purchase_items[0].purchase_request_item){
      _partBatchField.expressions.hide = 'true';
      _matBatchField.expressions.hide = 'false';
    }
    if (this.selectedPo.purchase_items[0].service_request_item){
      _matBatchField.expressions.hide = 'true';
      _partBatchField.expressions.hide = 'false';
    }
  }
}
